import React from 'react';
import { Typography, Modal, Button, makeStyles, Container } from '@material-ui/core';
import { buttonStyle } from '../../common/common';
import { Text } from '../../common/Language';
import Loader from '../loader';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

interface IModal {
  message: string;
  messageSecondary?: string;
  icon?: any;
  openModal: boolean;
  handleModalYesClicked: any;
  handleModalNoClicked: any;
  showLoader?: boolean;
  showSuccess?: boolean;
  showFailure?: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px',
  },
  alertButton1: {
    ...buttonStyle,
    marginLeft: '55%',
    marginTop: '8px',
  },
  alertButton2: {
    ...buttonStyle,
    marginLeft: '10px',
    marginTop: '8px',
  },
  icon: {
    marginTop: '10px',
    marginRight: '5px',
  },
  secondaryMessage: {
    fontSize: '10px',
    marginBottom: '10px',
  },
  successIcon: {
    fontSize: '75px',
  },
  successWindow: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minWidth: '100%',
    maxWidth: '100%',
    margin: 'auto',
  }
}));

const renderLoader = () => {
  return (
    <Container maxWidth='md' component='div' className='loaderStyle'>
      <Loader />
    </Container>
  );
};

export const ModalComponent = (props: IModal) => {
  const classes = useStyles();
  let message = <Text tid={props.message} />;
  let messageSecondary = <Text tid={props.messageSecondary} />;
  const handleClose = props.handleModalNoClicked;

  const RenderContent = () => {
    if (props.showSuccess) {
      return (
        <div className={classes.successWindow}>
          <div style={{width: '100%', alignItems: 'right', position: 'relative'}}>
                <CloseIcon style={{position: 'absolute', right: '-15px', top: '-15px', cursor: 'pointer'}} onClick={handleClose}/>
          </div>
          <div className={classes.successIcon}>
          <CheckCircleIcon
              fontSize='inherit'
              htmlColor='#7680e8'
              className={classes.icon}
            />
          </div>
          <Typography variant='h5' component='h2' color='textPrimary'>
            {'Job action successful'}
          </Typography>
        </div>
      )
    }
    if (props.showFailure) {
      return (
        <div className={classes.successWindow}>
          <div style={{width: '100%', alignItems: 'right', position: 'relative'}}>
                <CloseIcon style={{position: 'absolute', right: '-15px', top: '-15px', cursor: 'pointer'}} onClick={handleClose}/>
          </div>
          <div className={classes.successIcon}>
          <SentimentVeryDissatisfiedIcon
              fontSize='inherit'
              htmlColor='#7680e8'
              className={classes.icon}
            />
          </div>
          <Typography variant='h5' component='h2' color='textPrimary'>
            {'Job action failed'}
          </Typography>
        </div>
        
      )
    }
    return(
      <div>
        <div style={{ display: 'flex' }}>
          {props.icon && <div className={classes.icon}>{props.icon}</div>}
          <h6 id='simple-modal-description'>{message}</h6>
        </div>
        {props.messageSecondary && (
          <div className={classes.secondaryMessage}>
            <Typography color='textSecondary'>{messageSecondary}</Typography>
          </div>
        )}
        <Button
          variant='outlined'
          className={classes.alertButton1}
          onClick={props.handleModalYesClicked}
          size='small'
        >
          <Text tid='yes' />
        </Button>
        <Button
          variant='outlined'
          className={classes.alertButton2}
          onClick={props.handleModalNoClicked}
          size='small'
        >
          <Text tid='no' />
        </Button>
      </div>
    )
  }

  return (
    <Modal aria-describedby='simple-modal-description' open={props.openModal}>
      <div className={classes.modalContainer}>
        <div className={classes.paper}>
          {
            props.showLoader ?
            <div>
              {renderLoader()}
            </div> :
            <RenderContent/>
          }
        </div>
      </div>
    </Modal>
  );
};
