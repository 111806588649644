export const fetchJobLogs = (gitlabToken: string, stageLogParams: any): Promise<string> => {
    return new Promise(async(resolve, reject) => {
        const url = `https://gitlab.com/api/v4/projects/${stageLogParams.projectId}/jobs/${stageLogParams.stageId}/trace`
        const response: any = await fetch(url, { 
            method: 'get', 
            headers: new Headers({
                'PRIVATE-TOKEN': gitlabToken, Accept: '*/*', 'User-Agent': 'nodejs' , 'Content-Type': 'text/html; charset=UTF-8'
            }), 
        }).catch((error) => {
            return reject(error);
        });
        if(response) {
            response.text().then(function(data: string) {
                // https://stackoverflow.com/questions/25245716/remove-all-ansi-colors-styles-from-strings
                // https://stackoverflow.com/questions/39325414/line-break-in-html-with-n
                let filteredData = data.replace(/(section_start)[:\w]+(\r|\n)/g, '');
                filteredData = filteredData.replace(/(section_end)[:\w]+/g, '\t');
                filteredData = filteredData.replace(
                    /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, '');
                
                filteredData = filteredData.replace(/^(\n|\r)/gm, '');
                filteredData = filteredData.replace(/^ (\n|\r)/gm, '');
                filteredData = filteredData.replace(/\s+$/g, '');
                filteredData = filteredData.replace(/^/gm, 'pholderNumber@1 ');
                filteredData = filteredData.replace(/\r|\n/g, '');
                //let count = (filteredData.match(/pholderNumber@1/g) || []).length;
                const LENGTH_LIMIT = 1000000;
                const multiple = Math.floor(filteredData.length / LENGTH_LIMIT) + 1; //100000 is an arbitary chunk size
                let result = '';
                let previousCount = 0;
                for(let substringCount = 0; substringCount < multiple; substringCount += 1) {
                    const substring = substringCount === multiple - 1 ? filteredData.substring(substringCount * LENGTH_LIMIT) :
                            filteredData.substring(substringCount * LENGTH_LIMIT, (substringCount + 1) * LENGTH_LIMIT);

                    const res = addNumbering(substring, previousCount);
                    previousCount += (substring.match(/pholderNumber@1/g) || []).length;
                    result = result + res;
                }
                filteredData = result;
                
                return resolve(filteredData)
            });
        } else {
            return resolve('');
        }
    })
    
}

const addNumbering = (str: string, offset: number) => {
    let i = 1;
    while(str.indexOf('pholderNumber@1') !== -1) {
        //console.log("str.indexOf('pholderNumber@1'):", str.indexOf('pholderNumber@1'))
        str = str.replace('pholderNumber@1', `\n${i + offset}.`);
        i  += 1;
    }
    return str;
}