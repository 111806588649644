import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Box, createStyles, makeStyles, MuiThemeProvider, Theme, Tooltip, Typography } from '@material-ui/core';
import { BuildDatabaseStageDataItem, STATUS_CANCELED, STATUS_FAILED, STATUS_INPROGRESS, STATUS_PENDING, STATUS_SCHEDULED, STATUS_SKIPPED, STATUS_SUCCESS } from '../../../../model';
import { tooltipTheme } from '../../../../common/common';
import { convertSecondsToTime, getDateTimeFromMilli, getTimeFromMilli } from '../../../../utils/data';
import { Text } from '../../../../common/Language';
import AutorenewIcon from '@material-ui/icons/Autorenew';

export const RETRY = 'RETRY'; 

interface IProps {
    stage: BuildDatabaseStageDataItem;
    onClick: any;
    projectId:string;
    provideAction: boolean;
    takeAction: Function;
    isSelected?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stageBox: {
      marginTop: '10px',
      height: 'fit-content',
      border: '1px solid #ccc!important',
      borderRadius: '16px',
      display: 'inline-flex',
      padding: '10px',
    },
    stageBoxActive: {
        marginTop: '10px',
        height: 'fit-content',
        border: '2px solid #000!important',
        borderRadius: '16px',
        display: 'inline-flex',
        padding: '10px',
        backgroundColor: '#fff'
    },
    stagetext: {
      marginLeft: '8px',
      marginTop: '6px',
      cursor: 'default'
    },
    durationBox: {
        marginLeft: '6px',
        border: '1px solid #ccc!important',
        borderRadius: '16px',
        padding: '6px',
        //backgroundColor: '#f0f0f0',
        cursor: 'default',
        fontWeight: 500,
        whiteSpace: 'nowrap'
    },
    statusIconContainer: {
        cursor: 'pointer',
        marginTop: '6px'
    },
    actionIconContainer: {
        marginLeft: '8px',
        marginTop: '6px',
        cursor: 'pointer'
    }
  })
);

const renderIcon = (status: string) => {
    switch(status){
        case STATUS_SUCCESS:
            return (
                <CheckCircleIcon htmlColor='green'/>
            )
        case STATUS_FAILED:
            return(
                <CancelIcon htmlColor='red'/>
            )
        case STATUS_SKIPPED:
            return(
                <DoubleArrowIcon htmlColor='blue'/>
            )
        case STATUS_CANCELED:
            return (
                <BlockOutlinedIcon/>
            )
        case STATUS_INPROGRESS:
            return(
                <PlayCircleFilledWhiteRoundedIcon htmlColor='#F9C333'/>
            )
        case STATUS_PENDING:
            return (
                <WatchLaterIcon/>
            )
        case STATUS_SCHEDULED:
            return (
                <ScheduleOutlinedIcon/>
            )
        /* case 'OTHER':
            return(
                <FiberManualRecordOutlinedIcon/>
            ) */
        default:
            return (
                <FiberManualRecordOutlinedIcon/>
            )
    }
}

const StageBox = (props: IProps) => {
    const classes = useStyles();
    const {stage, provideAction, takeAction} = props;
    let timerHandler: any;

    const fireShowLogsEvent = () => {
        props.onClick(stage.stageId, props.projectId);
    }

    const handleClick = () => {
        clearHoverWaitInterval();
        fireShowLogsEvent();
    }

    const getActionType = () => {
        if(stage.status === STATUS_SUCCESS || stage.status === STATUS_FAILED) {
            return RETRY; 
        }
        return '';
    }

    const handleTakeAction = () => {
        const actionType = getActionType();
        takeAction(stage.stageId, actionType)
    }

    const fireMouseOver = () => {
        if(!timerHandler) {
            timerHandler = setTimeout(() => {
                fireShowLogsEvent();
            }, 3000);
        }
    }

    const clearHoverWaitInterval = () => {
        if (timerHandler) {
            clearTimeout(timerHandler);
            timerHandler = undefined;
        }
    }

    const fireMouseLeave = () => {
        clearHoverWaitInterval();
    }

    return (
        <Box className={props.isSelected ? classes.stageBoxActive : classes.stageBox}>
            <MuiThemeProvider theme={tooltipTheme}>
                <Tooltip
                    title={
                    <Typography className='tooltipTitleStyle'>
                        {stage.status}
                    </Typography>
                    }
                    placement='bottom'
                >
                    <div onMouseOver={fireMouseOver} onMouseLeave={fireMouseLeave} onClick={handleClick} className={classes.statusIconContainer}>
                        {renderIcon(stage.status)}
                    </div>
                </Tooltip>
            </MuiThemeProvider>
            {
                stage.startTimestamp || stage.endTimestamp || stage.pauseDuration ?
                <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                        title={
                            <div>
                                {
                                    stage.startTimestamp ?
                                    <Typography>
                                        <Text tid='startedOn'/>
                                        {`: ${getDateTimeFromMilli(stage.startTimestamp * 1000)} ${getTimeFromMilli(stage.startTimestamp * 1000)}`}
                                    </Typography> : <div/>
                                }
                                {
                                    stage.endTimestamp ?
                                    <Typography>
                                        <Text tid='endedOn'/>
                                        {`: ${getDateTimeFromMilli(stage.endTimestamp * 1000)} ${getTimeFromMilli(stage.endTimestamp * 1000)}`}
                                    </Typography> : <div/>
                                }
                                {
                                    stage.pauseDuration ?
                                    <Typography>
                                        <Text tid='waitTimeDuration'/>
                                        {`: ${convertSecondsToTime(stage.pauseDuration)} `}
                                    </Typography> : <div/>
                                }
                            </div>
                        }
                        placement='bottom'
                    >
                        <div>
                            <Typography color='textPrimary' className={classes.stagetext}>{stage.stageName}</Typography>
                        </div>
                    </Tooltip>
                    </MuiThemeProvider> :
                    <div>
                        <Typography color='textPrimary' className={classes.stagetext}>{stage.stageName}</Typography>
                    </div>
                }
            
                <MuiThemeProvider theme={tooltipTheme}>
                    <Tooltip
                        title={
                        <Typography className='tooltipTitleStyle'>
                            <Text tid='durationWord'/>
                        </Typography>
                        }
                        placement='bottom'
                    >
                        <div className={classes.durationBox}>
                            {`${convertSecondsToTime(stage.duration)}`}
                        </div>
                    </Tooltip>
                </MuiThemeProvider>
                {provideAction && (stage.status === STATUS_SUCCESS || stage.status === STATUS_FAILED) ? 
                <div className={classes.actionIconContainer}>
                    <MuiThemeProvider theme={tooltipTheme}>
                        <Tooltip
                            title={
                            <Typography className='tooltipTitleStyle'>
                                Retry Job
                            </Typography>
                            }
                            placement='bottom'
                        >
                            <AutorenewIcon onClick={handleTakeAction}/>
                        </Tooltip>
                    </MuiThemeProvider>
                </div>:
                <div/>}
        </Box>
    )
}

export default StageBox;