import React, { Fragment } from "react";
import TimelineCalendar from '../../common/metrics/timelineCalendar';
import TimelineButton from '../../common/metrics/timelineButton';

export default function PipelineViewTimeline(props: any) {
    const { buildsTimeline, updateBuildsData, getBuildsCustomDates } = props;
    return (
      <Fragment>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <TimelineButton
          timeline={buildsTimeline}
          updateData={(buildsTimeline: any) => updateBuildsData(buildsTimeline)}
        />
        &nbsp; &nbsp;
        <TimelineCalendar getCustomDates={getBuildsCustomDates} />
      </Fragment>
    );
  }