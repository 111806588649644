import React from 'react';
import { MuiThemeProvider, Typography, Tooltip } from '@material-ui/core';
import { tooltipTheme } from '../../../common/common';
import InfoIcon from '@material-ui/icons/Info';
import '../../../css/metrics/style.css';
import { Text } from '../../../common/Language';

export const CommonTooltip = (props: any) => {
    return (
        <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip
            title={
                <Typography className='tooltipTitleStyle'>
                    <Text tid={props.text} />
                </Typography>
            }
            >
            <InfoIcon className='reqInfoIconStyle' />
            </Tooltip>
        </MuiThemeProvider>
    )
}
  