import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDateFromMilliTimeframe, getFullDate } from '../../../../utils/data';
import { IRootState } from '../../../../reducers';
import { IReqStatusDataItem, JiraIssueTypes, JIRA_TYPE_IN_PROGRESS, JIRA_TYPE_OPEN, JIRA_TYPE_RESOLVED } from '../../../../model/metrics/requirementsData';
import { Http } from '../../../../utils';
import { ALL } from '../../../../pages/metrics/metric-select';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { CommonTooltip } from '../infoTooltip';
import { Loader } from '../../..';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Text } from '../../../../common/Language';
import ReactApexChart from 'react-apexcharts';
import { ITrendDataItem } from '../../../../model/metrics/doraData';

export default function ReqStatusChart(props: any) {
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [requirementStatusData, setRequirementStatusData] = useState<IReqStatusDataItem[]>([]);
  const [failureMsg, setFailureMsg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [series, setSeries] = useState<any>([]);
  const [userMsg, setUserMsg] = useState('Loading...');
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(0);
  const [trendData, setTrendData] = useState<
    ITrendDataItem[]
  >([]);

  const {setFocusIssueType} = props;

  let date = new Date();
  let yesterday = new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000);
  let one_week = getFullDate(
    new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  let one_month = getFullDate(
    new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)
  );
  let six_months = getFullDate(
    new Date(date.getTime() - 182 * 24 * 60 * 60 * 1000)
  );
  let one_year = getFullDate(
    new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000)
  );
  let ytd = `01 Jan ${date.getFullYear()}`;
  let custom_from_date = getFullDate(props.customDate[0]);
  let custom_to_date = getFullDate(props.customDate[1]);

  useEffect(() => {
    updateData(props.timeline);
  }, [props.customDate, props.timeline]);

  useEffect(() => {
    if (requirementStatusData.length > 0 && trendData.length > 0) {
      getIssueCount();
    }
  }, [requirementStatusData, trendData])

  useEffect(() => {
    setRequirementStatusData([]);
    setUserMsg('Loading...');
    setSeries([]);
    setTrendData([]);
    fetchData();
  }, [props.focusTeam, props.focusService, props.focusSubService, props.focusServiceType, props.itemType, props.itemPriority, props.selectedDateRange]);

  const fetchData = () => {
    let { timeline, focusTeam, focusService, focusSubService, focusServiceType, joinServiceAndSubService, itemType, itemPriority, selectedDateRange } = props;
    let url: string = '/api/metrics/reqs/status';
    let joiner = '?';
    if (focusTeam[0] !== ALL) {
      url = `${url}${joiner}teamId=${focusTeam.toString()}`;
      joiner = '&';
    }
    /* if (focusService[0] !== ALL && focusSubService[0] !== ALL) {
      url = `${url}${joiner}service=${joinServiceAndSubService()}`;
      joiner = '&';
    } else  */
    if (focusService[0] !== ALL) {
      url = `${url}${joiner}service=${focusService.join()}`;
      joiner = '&';
    }
    if (focusSubService[0] !== ALL) {
      url = `${url}${joiner}subService=${focusSubService.join()}`;
      joiner = '&';
    }
    if (focusServiceType[0] !== ALL) {
      url = `${url}${joiner}serviceType=${focusServiceType.join()}`;
      joiner = '&';
    }
    if (itemType[0] !== ALL) {
      url = `${url}${joiner}type=${itemType.toString()}`;
      joiner = '&';
    }
    if (itemPriority[0] !== ALL) {
      url = `${url}${joiner}priority=${itemPriority.toString()}`;
      joiner = '&';
    }
    url = `${url}${joiner}fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`;
    joiner = '&';

    Http.get({
      url,
      state: stateVariable,
    })
      .then((response: any) => {
        if (response) {
          setTimeout(() => {
            setUserMsg('');
          }, 10000);
            setRequirementStatusData(
              response.chartData.sort((a: any, b: any) => {
                return a.timestamp - b.timestamp;
              })
            );
            setTrendData(
              response.trendData.sort((a: ITrendDataItem, b: ITrendDataItem) => {
                return a.timestamp - b.timestamp;
              })
            );
          setLoader(false);
        } else {
          setLoader(false);
          setFailureMsg(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setFailureMsg(true);
        const perror = JSON.stringify(error);
        const object = JSON.parse(perror);
        if (object.code === 401) {
          history.push('/relogin')
        }
      });
  };

  const getDateRange = (dateRange: any) => {
    let { setRequirementsDateRange } = props;
    setRequirementsDateRange(dateRange);
  };

  const updateData = (timeline: string) => {
    switch (timeline) {
      case 'one_day':
        getDateRange({
          fromDate: new Date(yesterday).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'one_week':
        getDateRange({
          fromDate: new Date(one_week).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'one_month':
        getDateRange({
          fromDate: new Date(one_month).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'six_months':
        getDateRange({
          fromDate: new Date(six_months).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'one_year':
        getDateRange({
          fromDate: new Date(one_year).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'ytd':
        getDateRange({
          fromDate: new Date(ytd).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'all':
        getDateRange({
          fromDate: new Date(one_year).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'custom':
        getDateRange({
          fromDate: new Date(custom_from_date).getTime().toString(),
          toDate: new Date(custom_to_date).getTime().toString(),
        });
        break;
      default:
    }
  };

  const handleLegendClick = (chart: any, seriesIndex: any, opts: any) => {
    setFocusIssueType((focusIssueTypeRef: JiraIssueTypes[]) => {
      const orderedTypes: JiraIssueTypes[] = [JIRA_TYPE_RESOLVED, JIRA_TYPE_IN_PROGRESS, JIRA_TYPE_OPEN];
      const focusIssueTypeCopy = [...focusIssueTypeRef];
      if(focusIssueTypeCopy.indexOf(orderedTypes[seriesIndex]) !== -1) {
        focusIssueTypeCopy.splice(focusIssueTypeCopy.indexOf(orderedTypes[seriesIndex]), 1);
      } else {
        focusIssueTypeCopy.push(orderedTypes[seriesIndex]);
      }
      console.log({focusIssueTypeCopy });
      return focusIssueTypeCopy;
    });
  }

  const options: any = {
    chart: {
      id: 'status_chart',
      height: 250,
      type: 'area',
      /* toolbar: {
        show: false,
      }, */
      group: 'requirements'
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
   // colors: ['#00ad6b', '#FFC300', '#808080'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        onClick: handleLegendClick,
      },
    },
    /* xaxis: {
      tickAmount: 7
    }, */
    /* xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      labels: {
        datetimeUTC: false
      }
    }, */
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return value.toFixed();
        },
      },
      title: {
        text: 'Counts',
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
      y: {
        formatter: function (value: number) {
          return value;
        },
        title: {
          formatter: (seriesName: string) => {
            let name = seriesName.split('(')[0];
            return `${name}:`;
          }
        },
      },
    },
    /* noData: {
      text: userMsg,
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '12.5px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
    }, */
  };

  const getIssueCount = () => {
    const countNew: any[] = [];
    const countInProgress: any[] = [];
    const countResolved: any[] = [];
    let totalCountNew = 0;
    let totalCountInProgress = 0;
    let totalCountResolved = 0;
    const trends: any[] = [];

    requirementStatusData.map((data: any) => {
      const date = getDateFromMilliTimeframe(data.timestamp, props.timeline, props.customDate[0], props.customDate[1])

      countNew.push({ x: date, y: data.countNew });
      totalCountNew += data.countNew;

      countInProgress.push({ x: date, y: data.countInProgress });
      totalCountInProgress += data.countInProgress;

      countResolved.push({ x: date, y: data.countResolved });
      totalCountResolved += data.countResolved;
    });

    trendData.map((data: ITrendDataItem) => {
      const date = getDateFromMilliTimeframe(data.timestamp, props.timeline, props.customDate[0], props.customDate[1])
      trends.push({x: date, 
        y: data.value ? data.value.toFixed(2) : 0
      });
    });

    setTotalCount(totalCountNew + totalCountInProgress + totalCountResolved);

    setSeries([
        {
          name: JIRA_TYPE_RESOLVED + `(${totalCountResolved})`,
          type: 'area',
          data: countResolved,
          color: '#00ad6b'
        },
         {
          name: JIRA_TYPE_IN_PROGRESS + `(${totalCountInProgress})`,
          type: 'area',
          data: countInProgress,
          //color: '#FFC300'
          color: '#4BB2DC'
        }, 
        {
          name: JIRA_TYPE_OPEN + `(${totalCountNew})`,
          type: 'area',
          data: countNew,
          color: '#808080'
        },
        {
          name: 'Trends',
          type: 'line',
          data: trends,
          color: '#090909'
        } 
    ]);
  };

  return (
    <div id='chart'>
      <div id='chart-timeline'>
        <Fragment>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='subtitle2' className='subTitleMetricStyle'>
                <Box fontWeight={700} mb={loader || failureMsg ? 1.5 : 0}>
                  <Text tid='issueCount' />
                  <CommonTooltip text='requirementsStatusChartTooltip'/>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div style={{float: 'right'}}>
                <span className='doraSubTitles'>
                  <Text tid='count' />
                </span>{' '}
                <span className='countText'>
                  {totalCount}
                </span>
              </div>
            </Grid>
          </Grid>
        </Fragment>
        {loader ? (
          <Container className='loaderStyle'>
            <Loader />
          </Container>
        ) : failureMsg ? (
          <Alert severity='error'>
            <AlertTitle>
              <Text tid='error' />
            </AlertTitle>
            <Text tid='somethingWentWrong' />
          </Alert>
        ) : (
          <div id="status_chart">
          <ReactApexChart
            options={options}
            series={series}
            type='area'
            height={260}
          />
          </div>
        )}
      </div>
    </div>
  );
}
