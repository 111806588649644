import { getTimeDifferenceInHrs, getUTCDateTime } from "./datetime";
import { getJiraId } from "./metricUtils";

const fs = require("fs");


export const downloadCSV = (data: any, filename?: string) => {
    const blob = new Blob([data], { type: 'txt/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename ? filename : 'opsai.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

export const objectToCsv = (data: any) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    
    csvRows.push(headers.join(','));

    for (const rows of data) {
      const values = headers.map((header) => {
        const escaped = ('' + rows[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };

  export const objectToCsvRequirements = async(data: any) => {
    const csvRows = [];
    const headersText: string[] = []; 
    const nameMap: any = {
        itemId: "Issue Id",
        itemType: "Issue Type",
        itemPriority: "Priority",
        teamId: "Team",
        service: "Service Component",
        projectName: "Project",
        createdOn: "Created On (UTC)",
        startedOn: "Started On (UTC)",
        closedOn: "Closed On (UTC)",
        leadTime: "Lead Time",
        cycleTime: "Cycle Time",
        status: "Status",
        url: "Issue URL"
    }
    const headersTextId = Object.keys(nameMap);

    headersTextId.forEach((el: string) => {
      headersText.push(nameMap[el]) 
    })
    csvRows.push(headersText.join(','));

    for (const rows of data) {
      const values = headersTextId.map((header) => {
        if(header === "cycleTime") {
          const cycleTime = rows.closedOn && rows.startedOn
            ? `${getTimeDifferenceInHrs(rows.closedOn * 1000, rows.startedOn * 1000)} hours`
            : '';
          const escaped = ('' + cycleTime).replace(/"/g, '\\"');
          return `"${escaped}"`;
        }
        if(header === "leadTime") {
          const leadTime = rows.closedOn && rows.createdOn
            ? `${getTimeDifferenceInHrs(rows.closedOn * 1000, rows.createdOn * 1000)} hours`
            : ''
          const escaped = ('' + leadTime).replace(/"/g, '\\"');
          return `"${escaped}"`;
        }
        if(!rows[header]) {
          return "";
        }
        if(header === "itemId") {
          const escaped = ('' + getJiraId(rows['url'])).replace(/"/g, '\\"');
          return `"${escaped}"`;
        }
        if(["closedOn", "startedOn", "createdOn"].indexOf(header) === -1) {
          const escaped = ('' + rows[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        }
        const date = getUTCDateTime(rows[header] * 1000)
        const escaped = ('' + date).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
};