export const getDateTime = (UTCDate: any) => {
  let dateString = new Intl.DateTimeFormat('en-IN', {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
  })
    .format(new Date(UTCDate))
    .toString()
    .replace(/\s/g, '-');
  let timeString = new Date(UTCDate).toTimeString().substring(0, 5);
  dateString = `${dateString}, ${timeString}`;
  return dateString;
};

const replaceAt = (dateStr: string, index: number, replacement: string) => {
  return dateStr.substring(0, index) + replacement + dateStr.substring(index + 1);
}

export const getUTCDateTime = (UTCDate: any) => {
  let date = new Date(UTCDate).toUTCString().substring(5,22);
  date = replaceAt(date, 2, '-')
  date = replaceAt(date, 6, '-')
  date = replaceAt(date, 11, ', ')
  date = date.substring(0,7) + date.substring(9)
  return date;
};

export const getTimeDifferenceInHrs = (endTime: number, startTime: number) => {
  let difference = endTime - startTime;
  return difference > 0 ? (difference/(3600 * 1000)).toFixed(1) : '-';
}

export const getDelayDate = (date: any) => {
  let dateString = new Intl.DateTimeFormat('en-IN', {
    month: 'short',
    day: '2-digit',
  })
    .format(new Date(date))
    .toString()
    .replace(/\s/g, ' ');
  return dateString;
};

export const getDate = (UTCDate: any) => {
  let dateString = new Intl.DateTimeFormat('en-IN', {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
  })
    .format(new Date(UTCDate))
    .toString()
    .replace(/\s/g, '-');
  return dateString;
};

export const getFullDate = (date: any) => {
  let year = date.getFullYear();
  let month = date.toLocaleString('default', { month: 'short' });
  let day = ('0' + date.getDate()).slice(-2);
  const dateformat = `${day} ${month} ${year}`;
  return dateformat;
};

export const getUTCDate = (UTCDate: any) => {
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(UTCDate);
  let month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(
    UTCDate
  );
  let date = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(UTCDate);
  const dateInUTC = Date.UTC(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(date)
  );
  return dateInUTC;
};

export const getTimeFromMilli = (milliseconds: any) => {
  let hours = `0${new Date(milliseconds).getHours()}`.slice(-2);
  let minutes = `0${new Date(milliseconds).getMinutes()}`.slice(-2);
  let seconds = `0${new Date(milliseconds).getSeconds()}`.slice(-2);

  let time = `${hours}:${minutes}:${seconds}`;
  return time;
};

export const getDateTimeFromMilli = (milliseconds: any) => {
  let date = new Date(milliseconds);
  return new Intl.DateTimeFormat('en-IN').format(date)
};

export const getTimeReqMetrics = (milliseconds: any) => {
  let hours = `0${new Date(milliseconds).getHours()}`.slice(-2);
  let minutes = `0${new Date(milliseconds).getMinutes()}`.slice(-2);

  let time = `${hours}:${minutes}`;
  return time;
};

export const getDateFromMilliTimeframe = (millis: number, timeline: any, custom_from_date?: number, custom_to_date?: number) => {
  let date: string;
  switch (timeline) {
    case 'one_day':
      return getTimeReqMetrics(millis);
    
    case 'custom':
      if(custom_to_date && custom_from_date && ((custom_to_date - custom_from_date) > 24 * 60 * 60 * 1000)) {
        return getDateTimeFromMilli(millis);
      } else {
        return getTimeReqMetrics(millis);
      }
    default:
      return getDateTimeFromMilli(millis);

  }
}

export const getDaysfromHours = (val: number) => {
  const value = val / 3600; // converting to hrs from seconds
  if (value > 24) {
    let days: string = Math.floor(value / 24).toString();
    let hrs: string = (value % 24).toFixed(0);
    return days + ' days ' + hrs + ' hrs';
  } else {
    return value.toFixed(2) + ' hrs';
  }
}

export const convertMinsToHrsMins = (mins: number) => {
  let hours: any = Math.floor(mins / 60);
  let minutes: any = mins % 60;
  if(hours === 0) {
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${minutes} minutes`;
  }
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}hours ${minutes} minutes`;
};

export const convertSecondsToTime = (secondsVal: number) => {
  let mins: any = Math.floor(secondsVal / 60);
  let seconds: any = Math.floor(secondsVal % 60);
  seconds = seconds < 10 ? '0' + seconds : seconds;
  if(mins === 0) {
    return `${seconds}s`;
  }
  let hours: any = Math.floor(mins / 60);
  let minutes: any = mins % 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  if(hours === 0) {
    return `${minutes}m ${seconds}s`;
  }
  hours = hours < 10 ? '0' + hours : hours;
  return `${hours}h ${minutes}m ${seconds}s`;
};
