import {
    getFullDate
} from "../../utils/data";

export const updateData = ({timeline, customDate, getBuildsDateRange}: any) => {
    let date = new Date();
    let today = getFullDate(date);
    let yesterday = new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000);
    let one_week = getFullDate(
        new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)
    );
    let one_month = getFullDate(
        new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)
    );
    let six_months = getFullDate(
        new Date(date.getTime() - 182 * 24 * 60 * 60 * 1000)
    );
    let one_year = getFullDate(
        new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000)
    );
    let ytd = `01 Jan ${date.getFullYear()}`;
    let custom_from_date = getFullDate(customDate[0]);
    let custom_to_date = getFullDate(customDate[1]);
    switch (timeline) {
        case 'one_day':
            getBuildsDateRange({
                fromDate: new Date(yesterday).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'one_week':
            getBuildsDateRange({
                fromDate: new Date(one_week).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'one_month':
            getBuildsDateRange({
                fromDate: new Date(one_month).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'six_months':
            getBuildsDateRange({
                fromDate: new Date(six_months).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'one_year':
            getBuildsDateRange({
                fromDate: new Date(one_year).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'ytd':
            getBuildsDateRange({
                fromDate: new Date(ytd).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'all':
            getBuildsDateRange({
                fromDate: new Date(one_year).getTime().toString(),
                toDate: new Date(date).getTime().toString(),
            });
            break;
        case 'custom':
            getBuildsDateRange({
                fromDate: new Date(custom_from_date).getTime().toString(),
                toDate: new Date(custom_to_date).getTime().toString(),
            });
            break;
        default:
    }
};