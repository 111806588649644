import { Container, createStyles, Divider, Grid, IconButton, makeStyles, Paper, TablePagination, Theme, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Loader } from '../../components';
import { fetchBuildData } from '../../components/metrics/builds/Table/fetchBuildData';
import StageBox from '../../components/metrics/builds/Table/StageBox';
import { IBuildsListDataItem, IServiceInfo } from '../../model';
import { IRootState } from '../../reducers';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ALL } from '../metrics/metric-select';
import TeamAndServiceSelection from '../metrics/metric-select/TeamAndServiceSelection';
import { updateData } from './timeline';
import { convertSecondsToTime, getDateTime } from '../../utils/data';
import PipelineViewTimeline from './PipelineViewTimeline';
import { Text } from '../../common/Language';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import GenericModal from '../../components/generic-modal';
import { ModalComponent } from '../../components/modal';
import { fetchJobLogs } from './fetchJobLogs';

const useStyles = makeStyles((theme) => ({
    toolbar: {
      fontSize: '12px !important',
      fontWeight: 'bold',
      display: 'initial',
    },
    paper: {
        marginTop: '1rem',
        //maxHeight: '30px',
        overflowX: 'auto',
        borderRadius: '5px',
        padding: '10px'
    },
    paperTop: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        position: 'relative',
        top: '120px',
    },
    pipelineListContainer: {
        border: '1px solid #b1b1b1',
        marginTop: '35px',
        paddingTop: '10px',
        borderRadius: '5px',
        boxShadow: '0px 0px 2px #a2a2a2',
        backgroundColor: '#e0e0e0',
        minHeight: '100vh',
        paddingBottom: '60px'
    },
    modalContent: {
        maxHeight: '50vh',
        overflow: 'scroll'
    },
    arrowIcon: {margin: '12px 5px 0px 5px', display: 'inherit'},
}));

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

let now = new Date();
let todayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate());
let todayEnd = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  23,
  59,
  59,
  999
);

const ViewPipelines = (props: RouteComponentProps) => {
    const classes = useStyles();
    const [params, setParams] = useState({});
    const [buildsData, setBuildsData] = useState<IBuildsListDataItem[]>([]);
    const [loader, setLoader] = useState(true);
    const [focusTeam, setFocusTeam] = useState<string[]>([ALL]);
    const [focusService, setFocusService] = useState<string[]>([ALL]);
    const [focusSubService, setFocusSubService] = useState<string[]>([ALL]);
    const [focusServiceType, setFocusServiceType] = useState<string[]>([ALL]);
    const [focusPipelineStatus, setFocusPipelineStatus] = useState<string[]>([ALL]);
    const [timeline, setTimeline] = useState('one_day');
    const [buildsDateRange, setBuildsDateRange] = useState({});
    const [customDate, setCustomDate] = useState([
        todayBegin,
        todayEnd,
    ]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [renderSkip, setRenderSkip] = useState(true);
    const [gitlabToken, setGitlabToken] = useState('');
    const [showLogs, setShowLogs] = useState(false);

    /* Pipeline job related states */
    const [stageLogParams, setStageLogParams] = useState<any>({});
    const [stageLogs, setStageLogs] = useState('');
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [jobActionParams, setJobActionParams] = useState({});
    const [showTakeJobActionModal, setShowTakeJobActionModal] = useState(false);
    const [showJobModalLoader, setShowJobModalLoader] = useState(false);
    const [showJobModalSuccess, setShowJobModalSuccess] = useState(false);
    const [showJobModalFailure, setShowJobModalFailure] = useState(false);

    const stateVariable = useSelector((state: IRootState) => {
        return state;
    });
    const history = useHistory();

    const getBuildsDateRange = (dateRange: any) => {
        setBuildsDateRange(dateRange)
    }

    useEffect(() => {
        updateData({timeline: 'custom', customDate, getBuildsDateRange});
    }, [customDate]);

    useEffect(() => {
        updateData({timeline, customDate, getBuildsDateRange});
    }, [timeline]);

    const fetchData = async(pipelineParamsData?: any) => {
        const response: any = await fetchBuildData(pipelineParamsData, stateVariable).catch((error) => {
            setLoader(false);
            const perror = JSON.stringify(error);
            const object = JSON.parse(perror);
            if (object.code === 401) {
              history.push('/relogin')
            } else {
              history.push('/error')
            }
        });
        if (response && response.chartData) {
            const chronologicallySorted = response.chartData.sort((a: IBuildsListDataItem, b: IBuildsListDataItem) => {
                if(!a.endTimestamp && !b.endTimestamp) {
                    return 0;
                }
                if(!a.endTimestamp) {
                    return 1;
                }
                if(!b.endTimestamp) {
                    return -1;
                }
                return a.endTimestamp > b.endTimestamp ? -1 : 1;
            });
            setBuildsData(chronologicallySorted);
            if(response.token) setGitlabToken(response.token)
            setLoader(false);
        } else {
            setLoader(false);
        };
    }

    useEffect(() => {
        if(!renderSkip) {
            const pipelineParams: any = {};
            pipelineParams.timeline = timeline;
            pipelineParams.focusTeam = focusTeam;
            pipelineParams.focusService = focusService;
            pipelineParams.focusSubService = focusSubService;
            pipelineParams.focusServiceType = focusServiceType;
            pipelineParams.focusPipelineStatus = focusPipelineStatus;
            pipelineParams.selectedDateRange = buildsDateRange;
            setLoader(true);
            if(JSON.stringify(params) !== JSON.stringify(pipelineParams)) {
                setParams(pipelineParams);
                fetchData(pipelineParams);
            }
        } else {
            setRenderSkip(false);
        }
    }, [focusTeam, focusService, focusSubService, focusServiceType, buildsDateRange, focusPipelineStatus])

    const getBuildsCustomDates = (dateRange: any) => {
        setCustomDate(dateRange);
        setTimeline('custom');
    };

    const updateBuildsData = (buildsTimeline: any) => {
        setTimeline(buildsTimeline);
        setCustomDate([todayBegin, todayEnd]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    function TablePaginationActions(props: TablePaginationActionsProps) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;
      
        const handleFirstPageButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>
        ) => {
          onChangePage(event, 0);
        };
      
        const handleBackButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>
        ) => {
          onChangePage(event, page - 1);
        };
      
        const handleNextButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>
        ) => {
          onChangePage(event, page + 1);
        };
      
        const handleLastPageButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>
        ) => {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <div className={classes.root}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label='first page'
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label='previous page'
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label='next page'
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label='last page'
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </div>
        );
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchJobLevelLogs = async() => {
        const response = await fetchJobLogs(gitlabToken, stageLogParams);
        if(response ) {
            setShowModalLoader(false);
            setStageLogs(response);
        } else {
            setShowModalLoader(false);
            setStageLogs("We are unable to fetch the logs at this moment. Please try later.");
        }
    }

    const postJobLevelAction = async() => {
        const url = `https://gitlab.com/api/v4/projects/${stageLogParams.projectId}/jobs/${stageLogParams.stageId}/retry`
        return fetch(url, { 
            method: 'put', 
            headers: new Headers({
                'PRIVATE-TOKEN': gitlabToken, Accept: '*/*', 'User-Agent': 'nodejs'
            }), 
        });
    }

    useEffect(() => {
        if(showLogs && stageLogParams.stageId && stageLogParams.projectId) {
            fetchJobLevelLogs()
        }
    }, [stageLogParams, showLogs])

    const onStageClick = (stageId: string, projectId: string) => {
        setShowLogs(true);
        setShowModalLoader(true)
        setStageLogParams({stageId, projectId});
    }

    const handleCloseLogs = () => {
        setShowLogs(false);
        setStageLogs('');
        setStageLogParams({});
    }

    const handleJobAction = (jobId: string, actionType: string) => {
        setJobActionParams({jobId, actionType});
        setShowTakeJobActionModal(true);
    }

    const handleJobModalNoClicked = () => {
        setJobActionParams({});
        setShowTakeJobActionModal(false)
        setShowJobModalSuccess(false);
        setShowJobModalFailure(false);
    }

    const handleJobModalYesClicked = async() => {
        //Call the function
        console.log(jobActionParams)
        setShowJobModalLoader(true);
        /*const response = await postJobLevelAction();
        if(response && response.status) {
            if(response.status >= 200 && response.status < 300) {
                setShowJobModalLoader(false);
                setShowJobModalSuccess(true);
            } else {
                setShowJobModalLoader(false);
                setShowJobModalFailure(true);
            }
        }; */
        setTimeout(() => {
            setShowJobModalLoader(false);
            setShowJobModalSuccess(true);
        }, 5000)
    }

    return (
        <div>
            <TeamAndServiceSelection focusTeam={focusTeam}
                focusService={focusService}
                focusSubService={focusSubService}
                focusServiceType={focusServiceType}
                focusPipelineStatus={focusPipelineStatus}
                setFocusTeam={setFocusTeam}
                setFocusSubService={setFocusSubService}
                setFocusService={setFocusService}
                setFocusServiceType={setFocusServiceType}
                setServiceListParent={() => {}}
                setFocusPipelineStatus={setFocusPipelineStatus}
                isViewPipeline={true}
            />
            <Container maxWidth='lg' className={classes.container}>
            <div className={classes.pipelineListContainer}>
                <Container>
                    <Grid container >
                    <Grid item md={7} >
                    <Typography variant='h6'>
                        <Text tid='viewPipelines'/>:
                        <div className={classes.toolbar}>
                            <PipelineViewTimeline
                                buildsTimeline={timeline}
                                //                buildsCustomDate={buildsCustomDate}
                                updateBuildsData={(timeline: any) =>
                                updateBuildsData(timeline)
                                }
                                getBuildsCustomDates={(dateRange: any) =>
                                getBuildsCustomDates(dateRange)
                                }
                            />
                        </div>
                    </Typography>
                    </Grid>
                    <Grid item md={5}>
                    <TablePagination
                        labelRowsPerPage='Records per page'
                        rowsPerPageOptions={[10, 20, 50]}
                        component='div'
                        count={buildsData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelDisplayedRows={({ to, count }) => `${to} of ${count}`}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    </Grid>
                    </Grid>
                    <div>
                        {loader?
                            <Container className='loaderStyle'>
                                <Loader />
                            </Container> :
                            <div>
                            {buildsData.length ?
                            <div> 
                            {buildsData.map((data, i) => {
                                if((i < rowsPerPage * page) || (i >= rowsPerPage * (page + 1))) {
                                    return <div key={data.buildNum}/>;
                                }
                                return (
                                    <Paper key={data.buildNum} className={classes.paper}>
                                        <Grid container className={classes.paperTop}>
                                            <Grid item md={3}>
                                                <div style={{display: 'flex', marginTop: '5px'}}>

                                                <Typography style={{fontWeight: 600}}>Build Number:&nbsp;</Typography>
                                                <Typography>
                                                    <a
                                                        href={data.url}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        style={{ textDecoration: 'underline' }}
                                                    >
                                                        {data.buildNum}
                                                    </a>
                                                </Typography>
                                                </div>
                                                <div style={{display: 'flex', marginTop: '5px'}}>
                                                <Typography style={{fontWeight: 600}}><Text tid="status" />:&nbsp;</Typography>
                                                <Typography>{data.status}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item md={3} style={{display: 'flex'}}>
                                                <Typography style={{fontWeight: 600}}>
                                                    <Text tid="team"/>:&nbsp;
                                                </Typography>
                                                <Typography>{data.teamId}</Typography>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div style={{display: 'flex'}}>
                                                <Typography style={{fontWeight: 600}}>
                                                    <Text tid="startDate"/>&nbsp;</Typography>
                                                <Typography>{data.startTimestamp ? getDateTime(data.startTimestamp) : '-'}</Typography>
                                                </div>
                                                <div style={{display: 'flex', marginTop: '5px'}}>
                                                <Typography style={{fontWeight: 600}}> <Text tid="endDate"/>&nbsp;</Typography>
                                                <Typography>{data.endTimestamp ? getDateTime(data.endTimestamp) : '-'}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item md={2}>
                                                <div style={{display: 'flex'}}>
                                                <Typography style={{fontWeight: 600}}><Text tid="durationWord" />:&nbsp;</Typography>
                                                <Typography>{convertSecondsToTime(data.duration)}</Typography>
                                                </div>
                                                <div style={{display: 'flex'}}>
                                                <Typography style={{fontWeight: 600}}><Text tid="jobDuration" />:&nbsp;</Typography>
                                                <Typography>{convertSecondsToTime(data.jobDuration)}</Typography>
                                                </div>
                                                <Typography>{`(Total wait time: ${convertSecondsToTime(data.pauseDuration)})`}</Typography>
                                            </Grid>
                                        </Grid>
                                        <div>

                                        </div>
                                        <Divider/>
                                        <div style={{marginTop: '5px'}}>
                                        {data.stages.length ?
                                            <div style={{padding: '0px 10px 10px 10px'}}>
                                            {data.stages.slice(0).map((stage, i) => {
                                                if(i === data.stages.length - 1) {
                                                    return <StageBox key={stage.stageId} stage={stage} projectId={data.projectId} onClick={onStageClick}
                                                                provideAction={true} takeAction={handleJobAction}/>
                                                }
                                                return (
                                                    <div key={stage.stageId} style={{display: 'inline'}}>
                                                        <StageBox stage={stage} onClick={onStageClick} projectId={data.projectId} 
                                                            provideAction={true} takeAction={handleJobAction}/>
                                                        <div className={classes.arrowIcon}>
                                                            <ArrowForwardIcon />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div> : <div style={{textAlign: 'center', padding: '30px'}}>
                                                There are no stages on this pipeline.
                                            </div>
                                        }
                                        </div>
                                    </Paper>
                                )
                            })
                        }
                    </div> : <div style={{textAlign: 'center', padding: '30px'}}>
                        There are no pipelines to show.
                    </div>}
                    </div>}
                    </div>
                    <Grid container>
                        <Grid item md={7}></Grid>
                        <Grid item md={5} style={{position: 'absolute', bottom: '30px', right: '30px'}}>
                            <TablePagination
                                labelRowsPerPage='Records per page'
                                rowsPerPageOptions={[10, 20, 50]}
                                component='div'
                                count={buildsData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelDisplayedRows={({ to, count }) => `${to} of ${count}`}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <GenericModal openModal={showLogs} handleClose={handleCloseLogs} showLoader= {showModalLoader}>
               <div className={classes.modalContent}>
               <code style={{whiteSpace: "pre-wrap"}}>{stageLogs}</code></div>
            </GenericModal>
            <ModalComponent message="Take Action?" messageSecondary='Do you really want to retry this job?'
                handleModalNoClicked={handleJobModalNoClicked} handleModalYesClicked={handleJobModalYesClicked}
                openModal={showTakeJobActionModal} showLoader={showJobModalLoader} showSuccess={showJobModalSuccess}
                showFailure={showJobModalFailure}
            />
            </Container>
        </div>
    )
}

export default withRouter(ViewPipelines);
