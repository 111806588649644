import React, { useEffect, useState, Fragment, version } from 'react';
import {
  Card,
  Container,
  Grid,
  Paper,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssessmentWiseChart from './assessment-wise';
import TeamWiseChart from './team-wise';
import Title from './common/title';
import { fetchTeamAssessments, useActions } from '../../actions';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducers';
import { Http } from '../../utils';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/loader';
import { ICategoryWiseResultsObj, ITeamAssessment, ITeamInfo, QuestionnaireCategoryWiseResults } from '../../model';
import { Text } from '../../common/Language';
import '../../css/assessments/style.css';
import { getUserLevels } from '../../components/admin/dashboard/methods/getUserLevels';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '60%',
    padding: '15px',
    textAlign: 'center',
    margin: '5% 20%',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    position: 'relative',
    top: '100px',
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: '400px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  assessmentSelect: {
    minWidth: '30%',
  },
  teamSelect: {
    minWidth: '40%',
  },
  categoryChartContainer: {
    display: 'flex',
    gap: '20px',
    maxWidth: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  secondaryHeadings: {
    padding: '20px 20px 20px 30px'
  }
}));

export interface CategoryChart {
  [key: string] : {
    versions: number[];
    score: number[];
  }
}

const Trends = (props: any) => {
  const classes = useStyles();
  const [assessmentTypeAndVersion, setAssessmentTypeAndVersion] = useState('');
  const [assessmentType, setAssessmentType] = useState('');
  const [assessmentVersion, setAssessmentVersion] = useState('');
  const [selectedAssessment, setSelectedAssessment] = useState('');
  const [questionnaires, setQuestionnaires] = useState<any>(null);
  const [questionnairesFetch, setQuestionnairesFetch] = useState(false);
  const [focusTeam, setFocusTeam] = useState('');
  const [teamList, setTeamList] = useState<ITeamInfo[]>([]);
  const [assessmentList, setAssessmentList] = useState([{ id: '', name: '' }]);
  const [userLevels, setUserLevels] = useState<Object[]>([]);
  const [assessmentTeamsName, setAssessmentTeamsName] = useState<string[]>([]);
  const [assessmentTeamsScore, setAssessmentTeamsScore] = useState<number[]>(
    []
  );
  const [assessmentVersions, setAssessmentVersions] = useState<number[]>([]);
  const [assessmentVersionScore, setAssessmentVersionScore] = useState<
    number[]
  >([]);
  const [levelColor, setLevelColor] = useState<string[]>([]);
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [teamWiseCategoryRes, setTeamWiseCategoryRes] = useState<CategoryChart>({})

  const [
    categoryWiseResultsFetched,
    setCategoryWiseResultsFetched,
  ] = React.useState(false);
  const [categoryWiseResults, setCategoryWiseResults] = useState<QuestionnaireCategoryWiseResults[]>([]);

  useEffect(() => {
    getQuestionnaires();
    getTeams();
    fetchCwresults();
    getLevels();
    window.scrollTo(0, 0);
  }, []);

  const getLevels = async() => {
    const response = await getUserLevels(stateVariable).catch((error: any) => {
      const perror = JSON.stringify(error);
      const object = JSON.parse(perror);
      if (object.code === 401) {
        props.history.push('/relogin');
      }
    });
    if(response && response.length) {
      setUserLevels(response);
    }
  }

  const fetchCwresults = () => {
    Http.get({
      url: `/api/v2/cwresults/`,
      state: stateVariable,
    })
    .then((response: any) => {
      if(response && response.length) {
        setCategoryWiseResults(response);
        setCategoryWiseResultsFetched(true);
      }
    })
    .catch((error) => {
      const perror = JSON.stringify(error);
      const object = JSON.parse(perror);
      if (object.code === 401) {
        props.history.push('/relogin');
      }
    });
  }

  useEffect(() => {
    if(categoryWiseResults.length && selectedAssessment && focusTeam) {
      let versions = [];
      let versionScores = [];
      const dataObj: any[] = [];
      const categoryChartObj: any = {};
      for (const cwresult of categoryWiseResults) {
        if(cwresult.questionnaireIdVersion.indexOf(selectedAssessment) !== -1) {
          const questionnaireIdVersionSplit = cwresult.questionnaireIdVersion.split('_')
          const version = questionnaireIdVersionSplit[1];
          let numberOfassessment = 0;
          let totalScore = 0;
          for (const result of Object.values(cwresult.result)) {
            if(result.teamId === focusTeam) {
              const categoryWiseResult = result.categoryWiseResults;
              let score = 0;
              let maxScore = 0;
              for(const key of Object.keys(categoryWiseResult)) {
                const val = categoryWiseResult[key];
                if(categoryChartObj[key]) {
                  if(categoryChartObj[key][version]) {
                    categoryChartObj[key][version].totalPercentage += val.percentage;
                    categoryChartObj[key][version].totalNumber += 1;
                  } else {
                    categoryChartObj[key][version] = {
                      totalPercentage: val.percentage,
                      totalNumber: 1
                    }
                  }
                } else {
                  categoryChartObj[key] = {
                    [version]: {
                      totalPercentage: val.percentage,
                      totalNumber: 1
                    }
                  }
                }
                score += val.score;
                maxScore += val.maxScore
              }
              totalScore += Math.round(score / maxScore * 100);
              numberOfassessment += 1;
            }
          }
          dataObj.push({
            avgScore: Math.round(numberOfassessment ? totalScore/numberOfassessment : 0),
            version: parseInt(version, 10)
          })
        }
      }
      dataObj.sort((a, b) => {
        return a.version > b.version ? 1 : -1;
      })
      let sum = 0;
      for(const data of dataObj) {
        versions.push(data.version);
        versionScores.push(data.avgScore);
        sum += data.avgScore
      }
      if(sum) {
        setAssessmentVersionScore(versionScores);
        setAssessmentVersions(versions);
      } else {
        setAssessmentVersionScore([]);
        setAssessmentVersions([]);
      }
      const teamWiseCategroyResCopy: CategoryChart = {};
      const sortedKeys = Object.keys(categoryChartObj).sort();
      for (const key of sortedKeys) {
        const val = categoryChartObj[key];
        teamWiseCategroyResCopy[key] = {
          versions: [],
          score: [],
        }
        for(const ver of versions) {
          teamWiseCategroyResCopy[key].versions.push(parseInt(ver, 10));
          if(val[ver]) {
            teamWiseCategroyResCopy[key].score.push(val[ver].totalNumber ? 
              Math.round(val[ver].totalPercentage/val[ver].totalNumber) : 0);
          } else {
            teamWiseCategroyResCopy[key].score.push(0);
          }
          
        }
      }
      setTeamWiseCategoryRes(teamWiseCategroyResCopy);
    }
  }, [categoryWiseResults, focusTeam, selectedAssessment])

  useEffect(() => {
    if(questionnaires) {
      let teamQuestonnaires: any[] = [];
      const map = new Map();
      for (const item of questionnaires) {
        if (!map.has(item.displayName)) {
          map.set(item.displayName, true); // set any value to Map
          teamQuestonnaires.push({
            id: item.questionnaireId,
            name: item.displayName,
          });
        }
      }
      setSelectedAssessment(teamQuestonnaires[0].id);
      setAssessmentList(teamQuestonnaires);
    }
  }, [questionnaires])

  useEffect(() => {
    if(teamList.length) {
      let teamsName: string[] = [];
      let teamsScore: number[] = [];
      let colors: string[] = [];
      let dataObj: any[] = [];
      const questionnaireKey = `${assessmentType}_${assessmentVersion}`;
      for(const team of teamList) {
        if(team.assessmentData && team.assessmentData[questionnaireKey]) {
          const teamName = team.teamName;
          const averageScore = Math.round(team.assessmentData[questionnaireKey].totalScore /
                                team.assessmentData[questionnaireKey].numberOfAssessments);
          let color = '';
          userLevels.forEach((level: any) => {
            if (
              averageScore >= level.lowerLimit &&
              averageScore <= level.upperLimit
            ) {
              color = level.color;
            }
          });
          dataObj.push({
            teamName, averageScore, color
          })
        }
      }
      dataObj.sort((a,b) => {
        return a.averageScore > b.averageScore ? 1 : -1;
      })
      for (const item of dataObj) {
        teamsName.push(item.teamName);
        teamsScore.push(item.averageScore);
        colors.push(item.color);
      }
      setLevelColor(colors);
      setAssessmentTeamsName(teamsName)
      setAssessmentTeamsScore(teamsScore)
    }
  }, [teamList, assessmentType, assessmentVersion]);

  const getTeams = () => {
    Http.get({
      url: `/api/v2/teamlist`,
      state: stateVariable,
    })
      .then((response: any) => {
        const teamListCopy = [...response].filter((a: any) => a.active === 'true');
        let teamListSorted = teamListCopy.sort((a: any, b: any) => {
          return a.teamName.localeCompare(b.teamName);
        })
        setTeamList(teamListSorted);
        setFocusTeam(teamListSorted[0].teamId);
      })
      .catch((error: any) => {
        const perror = JSON.stringify(error);
        const object = JSON.parse(perror);
        if (object.code === 401) {
          props.history.push('/relogin');
        }
      });
  };

  const handleChangeQuestonnaireValue = (event: any) => {
    let assessmentTypeAndVersion = event.target.value;
    let id = '';
    let version = '';
    id = assessmentTypeAndVersion.substring(
      0,
      assessmentTypeAndVersion.indexOf('+')
    );
    version = assessmentTypeAndVersion.substring(
      assessmentTypeAndVersion.indexOf('+') + 1
    );

    setAssessmentType(id);
    setAssessmentVersion(version);
    setAssessmentTypeAndVersion(assessmentTypeAndVersion);
  };

  const getQuestionnaires = () => {
    Http.get({
      url: `/api/v2/assignment?dashboard=true`,
      state: stateVariable,
    })
      .then((response: any) => {
        const filteredQuestionnaires: any = [];
        response.questionnaires.forEach((el: any) => {
          if (response.questionnaireSelected.includes(el.questionnaireId)) {
            filteredQuestionnaires.push(el);
          }
        });
        setQuestionnairesFetch(true);
        setQuestionnaires(
          filteredQuestionnaires.sort((a: any, b: any) => {
            return a.displayName.localeCompare(b.displayName);
          })
        );
      })
      .catch((error) => {
        const perror = JSON.stringify(error);
        const object = JSON.parse(perror);
        if (object.code === 401) {
          props.history.push('/relogin');
        } else {
          props.history.push('/error');
        }
      });
  };

  const handleChangeFocusTeamValue = (event: any) => {
    let team = event.target.value;
    setFocusTeam(team);
  };

  const handleChangeTeamQuestonnaire = (event: any) => {
    let assessment = event.target.value;
    setSelectedAssessment(assessment);
  };

  const renderAssessmentWiseTrends = () => {
    return (
      <Fragment>
        <Grid
          container
          spacing={3}
          className='topScrollContainerAsssessment'
          id='assessmentWise'
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom style={{paddingLeft: '30px', fontWeight: 500}}>
                  <Text tid='assessmentWiseReport' />
                </Typography>
              </Grid>
              <div style={{paddingLeft: '30px'}}>
                <FormControl
                  className={classes.assessmentSelect}
                >
                  <InputLabel id='demo-simple-select-label'>
                    <Text tid='chooseAssessment' />
                  </InputLabel>
                  <Select
                    value={
                      assessmentTypeAndVersion !== ''
                        ? assessmentTypeAndVersion
                        : questionnaires
                          ? questionnaires.length > 0
                            ? `${questionnaires[0].questionnaireId}+${questionnaires[0].version}`
                            : ''
                          : ''
                    }
                    onChange={handleChangeQuestonnaireValue}
                  >
                    {questionnaires &&
                      questionnaires.map((opt: any) => {
                        return (
                          <MenuItem
                            key={`${opt.questionnaireId}+${opt.version}`}
                            value={`${opt.questionnaireId}+${opt.version}`}
                          >
                            {`${opt.displayName} - v${opt.version}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
              <Grid item xs={12}>
                {questionnairesFetch ? (
                  !assessmentTeamsName.length ? (
                    <Card className={classes.cardRoot}>
                      <Typography gutterBottom variant='h6' component='h2'>
                        <Text tid='noOneInTheTeamHasTakenTheAssessment' />
                        <AnnouncementIcon />
                      </Typography>
                    </Card>
                  ) : (
                    <AssessmentWiseChart
                      assessmentTeamsName={assessmentTeamsName}
                      assessmentTeamsScore={assessmentTeamsScore}
                      levelColor={levelColor}
                    />
                  )
                ) : (
                  <div style={{ marginBottom: '10%' }}>{renderLoader()}</div>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const renderTeamWiseTrends = () => {
    return (
      <Fragment>
        <Grid
          container
          spacing={3}
          className='topScrollContainerAsssessment'
          id='teamWise'
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom style={{paddingLeft: '30px', fontWeight: 500}}>
                  <Text tid='teamWiseReport' />
                </Typography>
              </Grid>
              <div style={{paddingLeft: '30px'}}>
                <FormControl className={classes.assessmentSelect}>
                  <InputLabel id='demo-simple-select-label'>
                    <Text tid='chooseAssessment' />
                  </InputLabel>
                  <Select
                    value={
                      selectedAssessment !== ''
                        ? selectedAssessment
                        : assessmentList[0].id
                    }
                    onChange={handleChangeTeamQuestonnaire}
                  >
                    {assessmentList &&
                      assessmentList.map((opt: any) => {
                        return (
                          <MenuItem key={opt.id} value={opt.id}>
                            {opt.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl className={classes.teamSelect}>
                  <InputLabel
                    id='demo-simple-select-label'
                    style={{ marginLeft: '25%' }}
                  >
                    <Text tid='chooseTeam' />
                  </InputLabel>
                  <Select
                    value={
                      focusTeam !== ''
                        ? focusTeam
                        : teamList
                          ? teamList.length > 0
                            ? teamList[0].teamId
                            : ''
                          : ''
                    }
                    style={{ marginLeft: '25%' }}
                    onChange={handleChangeFocusTeamValue}
                  >
                    {teamList.length > 0 &&
                      // mappedTeams.length > 0 &&
                      teamList.map((opt: any, i: number) => {
                        // if (!mappedTeams.includes(opt.teamId)) {
                        //   return;
                        // }
                        return (
                          <MenuItem key={i} value={opt.teamId}>
                            {opt.teamName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
              <Grid item xs={12}>
                {categoryWiseResultsFetched && questionnairesFetch ? (
                  assessmentVersions.length === 0 ? (
                    <Card className={classes.cardRoot}>
                      <Typography gutterBottom variant='h6' component='h2'>
                        <Text tid='noOneInTheTeamHasTakenTheAssessment' />
                        <AnnouncementIcon />
                      </Typography>
                    </Card>
                  ) : (
                    <div>
                      <Typography variant='h6' component='h4' style={{marginTop: '20px'}} className={classes.secondaryHeadings}>
                        <Text tid="aggregateScoreTrends"/>:
                      </Typography>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <TeamWiseChart
                          assessmentVersions={assessmentVersions}
                          assessmentVersionScore={assessmentVersionScore}
                          levelColor={levelColor}
                        />
                      </div>
                      <Divider/>
                      <Typography variant='h6' component='h4' className={classes.secondaryHeadings}>
                        <Text tid="categoryWiseTrends"/>:
                      </Typography>
                      <div className={classes.categoryChartContainer}>
                        {
                          Object.keys(teamWiseCategoryRes).map((category: string) => {
                            return (
                                <Paper key={category} style={{padding: '20px'}}>
                                  <Typography variant='h6' component='h5'>{category}</Typography>
                                  <TeamWiseChart
                                    assessmentVersions={teamWiseCategoryRes[category].versions}
                                    assessmentVersionScore={teamWiseCategoryRes[category].score}
                                    levelColor={levelColor}
                                    isCategory={true}
                                  />
                                </Paper>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                ) : (
                  <div style={{ marginBottom: '10%' }}>{renderLoader()}</div>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const renderLoader = () => {
    return (
      <Container maxWidth='md' component='div' className='loaderStyle'>
        <Loader />
      </Container>
    );
  };

  return (
    <Fragment>
      <Container maxWidth='md' className={classes.container}>
        {renderAssessmentWiseTrends()}
        {renderTeamWiseTrends()}
      </Container>
    </Fragment>
  );
};

export default withRouter(Trends);
