import React from 'react';
import { Text } from '../../common/Language';

const About = () => {
  return (
    <div>
      <p>
        <Text tid='aboutUs' />
      </p>
    </div>
  );
};
export default About;
