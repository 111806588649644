import { Container, createStyles, makeStyles, Modal, Theme } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../loader';

interface IProps {
    openModal: boolean;
    handleClose: any;
    children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperModal: {
      width: '80vw',
      //height: '30vh',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(4),
      display: 'relative',
      borderRadius: '10px'
    },
    modalContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '0px',
    },
  })
);

const renderLoader = () => {
    return (
      <Container maxWidth='md' component='div' className='loaderStyle'>
        <Loader />
      </Container>
    );
};

const GenericModal = (props: any) => {
    const {openModal, handleClose, showLoader} = props;
    const classes = useStyles();

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="pipeline-show-modal"
            aria-describedby="shows-pipeline-graphical-data"
        >
            <div className={classes.modalContainer}>
            <div className={classes.paperModal}>
            <div style={{width: '100%', alignItems: 'right', position: 'relative'}}>
                <CloseIcon style={{position: 'absolute', right: '-15px', top: '-15px', cursor: 'pointer'}} onClick={handleClose}/>
            </div>
            <div>
                {!showLoader ? props.children : renderLoader()}
            </div>
            </div>
            </div>
        </Modal>
    )
}

export default GenericModal;